<template>
    <v-container>

        <!-- Title Modul -->
        <v-card-title class="title-bar-modul">
            <v-icon>mdi-desktop-classic</v-icon>
            Admin - Inventory
        </v-card-title>

        <!-- Looping List Tab -->
        <v-tabs
        v-model="tabs"
        show-arrows="mobile"
        >
            <v-tab v-for="item in adminInventory" :key="item.tab" class="tab-icon">
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.tabName }}
            </v-tab>
        </v-tabs>

        <!-- Container Tabs -->
        <v-tabs-items v-model="tabs">

            <!-- Tab Item List -->
            <v-tab-item class="container-tab-modul">

                <item-datatable></item-datatable>

            </v-tab-item>
            
            <!-- Tab Record List -->
            <v-tab-item class="container-tab-modul">

                <record-datatable></record-datatable>

            </v-tab-item>

            <!-- Tab History -->
            <v-tab-item class="container-tab-modul">

                <history-datatable></history-datatable>

            </v-tab-item>

        </v-tabs-items>

    </v-container>
</template>

<script>
import dtItem from '@/components/systemAdmin/inventory/ItemDatatable'
import dtRecord from '@/components/systemAdmin/inventory/RecordDatatable'
import dtHistory from '@/components/systemAdmin/inventory/HistoryDatatable'
export default {
    components: {
        'item-datatable': dtItem,
        'record-datatable': dtRecord,
        'history-datatable': dtHistory,
    },
    data: () => ({

        tabs: null,
        adminInventory: [
            {tabName: "Item List", icon: "mdi-devices"},
            {tabName: "Record List", icon: "mdi-format-list-text"},
            {tabName: "History", icon: "mdi-clipboard-text-clock-outline"},
        ],

    }),
    methods: {

    },
    mounted(){

    }
    
}

</script>

<style lang="scss">
@import '~scss/main';



</style>
