<template>
  <v-container>
    <!-- Datatable -->
    <!-- Data Table -->
    <v-card class="mt-5 darkTable">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchDtAsset"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          outlined
          dense
          class="shrink"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="this.CheckLoadingTable == true"
        item-key="name"
        class="elevation-1"
        loading
        loading-text="Data is Loading... Please wait"
      ></v-data-table>
      <v-data-table
        v-if="this.CheckLoadingTable == false"
        id="dtCarBook"
        :items-per-page="5"
        :headers="headerAsset"
        :items="filteredDataAsset"
        :search="searchDtAsset"
        :custom-sort="customSort"
      >
        <!-- v-if="this.CheckLoadingTable == false"  -->
        <!-- :headers="headerAsset"
            :items="filteredDataAsset"
            :search="searchDtAsset" -->

        <template
          v-for="(header, i) in headerAsset"
          v-slot:[`header.${header.value}`]="{}"
        >
          <span @click.stop :key="i">
            <v-text-field
              :key="i"
              v-model="searchObj[header.value]"
              type="text"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
              style="font-weight: 100"
              :disabled="isDisable(i)"
            ></v-text-field>
            {{ header.text }}
          </span>
        </template>

      <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                    :color="controlBgColor(item.status)"
                    :class="controlColorFont(item.status)"
                    >
                    {{item.status}}
                    </v-chip>
                </template>

        <template v-slot:[`item.detailsAll`]="{ item }">
          <template>
            <v-icon dense class="primary--text" text @click="openFileDoc(item)"
              >mdi-file-document</v-icon
            >
          </template>
        </template>
      </v-data-table>
    </v-card>

    <template>
      <v-dialog
        v-model="dialogDisplayDetails"
        persistent
        scrollable
        max-height="10px"
        max-width="650px"
      >
        <v-card>
                <v-card-title class="title-bar-modul">
                <span class="text-h5">History Detail</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <tr
                        v-for="item in displayHistoryDetails"
                        :key="item.name"
                        >
                            <td class="font-weight-bold"><v-icon class="icon-text blackIcon--text" small>{{ item.icon }}</v-icon>{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogDisplayDetails = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            
            </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    CheckLoadingTable: true,
    searchDtAsset: "",
    searchObj: {},
    dialogDisplayDetails: false,
    headerAsset: [
      { text: "Name", value: "employeename" },
      { text: "Company", value: "companyname" },
      { text: "Asset Type", value: "assetname" },
      { text: "Received Date", value: "receivedt"},
      { text: "Tag No", value: "tagno" },
      { text: "Status", value: "status" },
      { text: "Details", value: "detailsAll" },
    ],
    bodyAsset: [],
    displayHistoryDetails: [
      { key: "Name", value: "" },
      { key: "Company", value: "" },
      { key: "Type", value: "" },
      { key: "Serial No", value: "" },
      { key: "Tag No", value: "" },
      { key: "Received Date", value: "" },
      { key: "Return Date", value: "" },
      { key: "Status", value: "" },
      { key: "Reason", value: "" },
      { key: "Given By", value: "" },
      { key: "Return To", value: "" },
    ],
  }),
  methods: {
    loadDataTable() {
      this.CheckLoadingTable = true;
      this.allData = [];

      axios
        .get(process.env.VUE_APP_API_URL + "asset/assethistory", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            (data[i].tagno == null || data[i].tagno == "") ? data[i].tagno = "N/A" : data[i].tagno;
            data[i].receivedt = this.convertDateString(data[i].receivedt)
            data[i].returndt = this.convertDateString(data[i].returndt)
            this.bodyAsset.push(data[i]);
          }

          this.allData = this.bodyAsset;
          this.headers = this.headerAsset;

          this.CheckLoadingTable = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    isDisable(index) {
      if (index == 6) {
        return true;
      } else {
        return false;
      }
    },

    controlColorFont(status){
            if (status == "ON HOLD") {
                return "white--text text-bold" 
            }
            else if(status == "RETURNED"){
                return "blackk--text text-bold" 
            }
        },

        controlBgColor(status){
            if (status == "ON HOLD") {
                return "green" 
            }
            else if(status == "RETURNED"){
                return "pending" 
            }
        },

    customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);
            
            items.sort((a, b) => {
                if (index[0]=='dtApplied' || index[0]=='startDateTime' || index[0]=='endDateTime') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                    if (!isDesc[0]) {
                        return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                    }
                    else {
                        return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                    }
                    }
                }
            });
            return items;
        },


    openFileDoc(item) {  
        this.displayHistoryDetails[0].value = item.employeename
        this.displayHistoryDetails[1].value = item.companyname
        this.displayHistoryDetails[2].value = item.assetname
        this.displayHistoryDetails[3].value = item.serialno
        this.displayHistoryDetails[4].value = item.tagno
        this.displayHistoryDetails[5].value = item.receivedt
        this.displayHistoryDetails[6].value = item.returndt
        this.displayHistoryDetails[7].value = item.status
        this.displayHistoryDetails[8].value = item.reason
        this.displayHistoryDetails[9].value = item.givenbyname
        this.displayHistoryDetails[10].value = item.returntoname
     
        this.dialogDisplayDetails = true;
    },
  },
  mounted() {
    this.loadDataTable();
  },
  computed: {
    filteredDataAsset() {
      if (this.searchObj) {
        return this.allData.filter((item) => {
          return Object.entries(this.searchObj).every(([key, value]) => {
            return (item[key] || "")
              .toLowerCase()
              .includes(value.toLowerCase());
          });
        });
      } else {
        return this.allData;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~scss/main";

.click-on {
  cursor: pointer;
}

.click-on:hover {
  background: lightblue;
}
</style>