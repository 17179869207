<template>
    <v-container>

        <!-- 5 Details Tab -->
        <v-row>

            <v-col>
                <v-card class="click-on" @click="openDialog('laptop')">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5 text-bold mb-1">
                            LAPTOP
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-icon x-large>mdi-laptop</v-icon>
                    </v-list-item>
                    <v-container>
                        <v-row class="mx-1 mb-1">
                            <v-card-text class="pa-0">
                                <span class="font-weight-bold">Available:</span>
                                {{this.statusLaptopAvailableCount}}
                            </v-card-text>
                            <v-card-text class="pa-0">
                                <span class="font-weight-bold">Not Available:</span>
                                {{this.statusLaptopNotAvailableCount}}
                            </v-card-text>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

            <v-col>
                <v-card class="click-on" @click="openDialog('desktop')">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5 text-bold mb-1">
                            DESKTOP
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-icon x-large>mdi-desktop-tower</v-icon>
                    </v-list-item>
                    <v-container>
                        <v-row class="mx-1 mb-1">
                            <v-card-text class="pa-0">
                                <span class="font-weight-bold">Available:</span>
                                {{this.statusDesktopAvailableCount}}
                            </v-card-text>
                            <v-card-text class="pa-0">
                                <span class="font-weight-bold">Not Available:</span>
                                {{this.statusDesktopNotAvailableCount}}
                            </v-card-text>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

            <v-col>
                <v-card class="click-on" @click="openDialog('server')">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5 text-bold mb-1">
                            SERVER
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-icon x-large>mdi-server</v-icon>
                    </v-list-item>
                    <v-container>
                        <v-row class="mx-1 mb-1">
                            <v-card-text class="pa-0">
                                <span class="font-weight-bold">Available:</span>
                                {{this.statusServerAvailableCount}}
                            </v-card-text>
                            <v-card-text class="pa-0">
                                <span class="font-weight-bold">Not Available:</span>
                                {{this.statusServerNotAvailableCount}}
                            </v-card-text>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

            <v-col>
                <v-card class="click-on" @click="openDialog('monitor')">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5 text-bold mb-1">
                            MONITOR
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-icon x-large>mdi-monitor</v-icon>
                    </v-list-item>
                    <v-container>
                        <v-row class="mx-1 mb-1">
                            <v-card-text class="pa-0">
                                <span class="font-weight-bold">Available:</span>
                                {{this.statusMonitorAvailableCount}}
                            </v-card-text>
                            <v-card-text class="pa-0">
                                <span class="font-weight-bold">Not Available:</span>
                                {{this.statusMonitorNotAvailableCount}}
                            </v-card-text>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

            <v-col>
                <v-card class="click-on" @click="openDialog('accessories')">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5 text-bold mb-1">
                            ACCESSORIES
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-icon x-large>mdi-mouse-variant</v-icon>
                    </v-list-item>
                    <v-container>
                        <v-row class="mx-1 mb-1">
                            <v-card-text class="pa-0">
                                <span class="font-weight-bold">Available:</span>
                                {{this.statusAccessoriesAvailableCount}}
                            </v-card-text>
                            <v-card-text class="pa-0">
                                <span class="font-weight-bold">Not Available:</span>
                                {{this.statusAccessoriesNotAvailableCount}}
                            </v-card-text>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>

        </v-row>

        <p class="text--secondary mt-3">
            <v-icon class="mr-1">mdi-cursor-default-click-outline</v-icon> Click on card above for add new inventory...
        </p>

        <!-- Datatable -->
        <!-- Data Table -->
        <v-card class="mt-5 darkTable">
            <v-card-title>
             <v-spacer></v-spacer>
                <v-btn
                class="mr-3 green"
                icon
                @click="exportExcel"
                >
                    <v-icon class="white--text">mdi-microsoft-excel</v-icon>
                </v-btn>
                <v-text-field
                    v-model="searchDtAsset"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                    dense
                    class="shrink"
                ></v-text-field>

            </v-card-title>
             <v-data-table
                v-if="this.CheckLoadingTable == true" 
                item-key="name"
                class="elevation-1"
                loading
                loading-text="Data is Loading... Please wait"
            ></v-data-table>
            <v-data-table
            v-if="this.CheckLoadingTable == false" 
            id="dtInventory"
            :items-per-page="5"
            :headers="headerAsset"
            :items="filteredDataAsset"
            :search="searchDtAsset"
            :custom-sort="customSort"
            >

            
            <!-- v-if="this.CheckLoadingTable == false"  -->
            <!-- :headers="headerAsset"
            :items="filteredDataAsset"
            :search="searchDtAsset" -->
            
                <template v-for="(header, i)  in headerAsset" v-slot:[`header.${header.value}`]="{  }"> 
                    <span @click.stop :key="i">
                        <v-text-field :key="i"
                        v-model="searchObj[header.value]"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        style="font-weight: 100;"
                        :disabled="isDisable(i)"
                        ></v-text-field>
                        {{ header.text }}
                    </span>
                </template>
                    
                <template v-slot:[`item.statusAsset`]="{ item }">
                    <v-chip
                    :color="controlBgColor(item.statusAsset)"
                    :class="controlColorFont(item.statusAsset)"
                    >
                    {{item.statusAsset}}
                    </v-chip>
                </template>

                <template v-slot:[`item.detailsAll`]="{ item }">
                    <template>
                    <v-icon
                        dense
                        class="mr-2 green--text"
                        @click="dialogEditInventory(item);"
                        >mdi-pencil</v-icon
                    >
                    <v-icon
                        dense
                        class="mx-2 red--text"
                        text
                        @click="dialogDeleteInventory(item.idemp, item.serialNo)"
                        >mdi-trash-can-outline</v-icon
                    >

                    <v-icon
                        dense
                        class="mx-2 primary--text"
                        text
                        @click="openFileDoc(item)"
                        >mdi-file-document</v-icon
                    >
                    
                    </template>

                    <!-- <v-icon
                    color="primary"
                    v-html="item.detailsAll"
                    @click="openFileDoc(item)"
                    > -->
                    <!-- </v-icon> -->
                </template>

                

            </v-data-table>
        </v-card>



        <!-- Dialog Form LAPTOP/DESKTOP/SERVER -->
        <template>
            <v-dialog
            v-model="dialogAddComputer"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>
                <v-card-title class="title-bar-modul">
                <span class="text-h5">ADD {{objData.type.toUpperCase()}}</span><v-icon></v-icon>
                </v-card-title>
                <v-card-text>
                <v-form ref="form">
                    <v-container>
                        <v-row>

                            <!-- Subtitle 1 -->
                            <v-col>
                                <div class="text-subtitle-1 text-bold">
                                    General Information
                                </div>
                            </v-col>
                            
                            <!-- Input Type -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    readonly
                                    :prepend-inner-icon="objData.iconType"
                                    label="Type"
                                    v-model="objData.type"
                                ></v-text-field>
                            </v-col>

                            <!-- Input status -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="statusLabel"
                                label="Status"
                                v-model="objData.statusAsset"
                                ></v-select>
                            </v-col>
                            
                            <!-- Input Brand -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Brand"
                                    v-model="objData.brandAsset"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Model -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Model"
                                    v-model="objData.modelAsset"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Asset Tag -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Asset Tag"
                                    v-model="objData.assetTag"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Serial Number -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Serial Number *"
                                    v-model="objData.serialNo"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Select Purchased By -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="company"
                                item-text="name"
                                item-value="idcompany"
                                label="Purchased By"
                                v-model="objData.idcompany"
                                ></v-select>
                            </v-col>
                            
                            <!-- Select Employee Name -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Employee Name"
                                v-model="objData.idemp"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- Date Purchased -->
                            <v-col cols="12" class="py-0">
                                <v-menu
                                v-model="menuPurchaseDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="objData.purchaseDate"
                                    label="Date Purchased"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="objData.purchaseDate"
                                    @input="menuPurchaseDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date Start -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrStarDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="objData.warrStartDate"
                                    label="Warranty Start"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="objData.warrStartDate"
                                    @input="menuWarrStarDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date End -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrEndDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="objData.warrEndDate"
                                    label="Warranty End"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="objData.warrEndDate"
                                    @input="menuWarrEndDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Select Registered By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                 :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Registered By"
                                v-model="objData.idempRegister"
                                ></v-autocomplete>
                            </v-col>

                            <!-- Select Disposed By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Disposed By"
                                v-model="objData.idempDispose"
                                ></v-autocomplete>
                            </v-col>

                            <!-- ================================================================================ -->

                            <!-- Subtitle 2 -->
                            <v-col>
                                <div class="text-subtitle-1 text-bold">
                                    Specification
                                </div>
                            </v-col>

                            <!-- Input Processor -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Processor"
                                    v-model="objData.processorAsset"
                                ></v-text-field>
                            </v-col>

                            <!-- Input RAM -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="RAM"
                                    v-model="objData.ramAsset"
                                ></v-text-field>
                            </v-col>

                            <!-- Input Storage -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Storage"
                                    v-model="objData.storageAsset"
                                ></v-text-field>
                            </v-col>

                            <!-- Input MAC Address -->
                            <v-col cols="12" class="py-0">
                                <v-card class="mb-5 pa-2" elevation="4">
                                    <v-container class="mb-4 px-0 pt-0 subtitle-2">
                                        MAC Address
                                    </v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" class="py-0">
                                            <v-text-field
                                                outlined
                                                dense
                                                label="LAN"
                                                v-model="objData.macLan"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="py-0">
                                            <v-text-field
                                                outlined
                                                dense
                                                label="WIFI"
                                                v-model="objData.macWifi"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <!-- Input Remarks -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Remarks"
                                    v-model="objData.remarksAsset"
                                ></v-text-field>
                            </v-col>

                            <!-- Upload Image -->
                            <v-col>
                                <v-file-input
                                outlined
                                dense
                                @change="uploadPictureAsset($event)"
                                v-model="uploadPic"
                                label="Upload Image (Asset)"
                                prepend-icon="mdi-camera"
                                ></v-file-input>
                                <a v-if="objData.picturePath != ''" :href="objData.picturePath" target="_blank">
                                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                </a>
                                <a v-if="objData.picturePath == ''" style="cursor:default;">
                                    <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                    <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                </a> 
                            </v-col>


                        </v-row>
                    </v-container>
                </v-form>
                
                <small class="blackk--text text-bold">*indicates required field</small><br>
                <small class="blackk--text text-bold">*All information to be filled in by Asset Inventory Manager</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogAddComputer = false"
                >
                    Close
                </v-btn>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="postAddInventory(objData.type)"
                >
                    Add
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template> 

        <template>
            <v-dialog
            v-model="dialogAddMonitor"
            persistent
            scrollable
            max-height="10px"
            max-width="650px">
                <v-card>
                    <v-card-title class="title-bar-modul">
                <span class="text-h5">ADD {{objData.type.toUpperCase()}}</span><v-icon></v-icon>
                </v-card-title>
                <v-card-text><v-form ref="form">
                    <v-container>
                        <v-row>

                            <!-- Subtitle 1 -->
                            <v-col>
                                <div class="text-subtitle-1 text-bold">
                                    General Information
                                </div>
                            </v-col>
                            <!-- Input Type -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    readonly
                                    :prepend-inner-icon="objData.iconType"
                                    label="Type"
                                    v-model="objData.type"
                                ></v-text-field>
                            </v-col>

                            <!-- Input status -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="statusLabel"
                                label="Status"
                                v-model="objData.statusAsset"
                                ></v-select>
                            </v-col>

                            <!-- Input Brand -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Brand"
                                    v-model="objData.brandAsset"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Model -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Model"
                                    v-model="objData.modelAsset"
                                ></v-text-field>
                            </v-col>

                             <!-- Input Monitor Size -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Monitor Size"
                                    v-model="objData.monitorSize"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Asset Tag -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Asset Tag"
                                    v-model="objData.assetTag"
                                ></v-text-field>
                            </v-col>

                            <!-- Input Serial Number -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Serial Number *"
                                    v-model="objData.serialNo"
                                ></v-text-field>
                            </v-col>
                            <!-- Select Purchased By -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="company"
                                item-text="name"
                                item-value="idcompany"
                                label="Purchased By"
                                v-model="objData.idcompany"
                                ></v-select>
                            </v-col>
                            
                            <!-- Select Employee Name -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Employee Name"
                                v-model="objData.idemp"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- Date Purchased -->
                            <v-col cols="12" class="py-0">
                                <v-menu
                                v-model="menuPurchaseDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="objData.purchaseDate"
                                    label="Date Purchased"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="objData.purchaseDate"
                                    @input="menuPurchaseDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date Start -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrStarDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="objData.warrStartDate"
                                    label="Warranty Start"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="objData.warrStartDate"
                                    @input="menuWarrStarDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date End -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrEndDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="objData.warrEndDate"
                                    label="Warranty End"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="objData.warrEndDate"
                                    @input="menuWarrEndDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Select Registered By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Registered By"
                                v-model="objData.idempRegister"
                                ></v-autocomplete>
                            </v-col>

                            <!-- Select Disposed By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                               :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Disposed By"
                                v-model="objData.idempDispose"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- Input Remarks -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Remarks"
                                    v-model="objData.remarksAsset"
                                ></v-text-field>
                            </v-col>

                            <!-- Upload Image -->
                            <v-col>
                                <v-file-input
                                outlined
                                dense
                                @change="uploadPictureAsset($event)"
                                v-model="uploadPic"
                                label="Upload Image (Asset)"
                                prepend-icon="mdi-camera"
                                ></v-file-input>
                                <a v-if="objData.picturePath != ''" :href="objData.picturePath" target="_blank">
                                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                </a>
                                <a v-if="objData.picturePath == ''" style="cursor:default;">
                                    <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                    <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                </a> 
                            </v-col>


                        </v-row>
                    </v-container>
                    </v-form>
                    <small class="blackk--text text-bold">*indicates required field</small><br>
                    <small class="blackk--text text-bold">*All information to be filled in by Asset Inventory Manager</small>
                            </v-card-text>
                            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogAddMonitor = false"
                >
                    Close
                </v-btn>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="postAddInventory(objData.type)"
                >
                    Add
                </v-btn>
                </v-card-actions>
                </v-card>
                
            </v-dialog>
        </template>

        <template>
            <v-dialog
            v-model="dialogAddAccessories"
            persistent
            scrollable
            max-height="10px"
            max-width="650px">
                <v-card>
                    <v-card-title class="title-bar-modul">
                <span class="text-h5">ADD {{objData.type.toUpperCase()}}</span><v-icon></v-icon>
                </v-card-title>
                <v-card-text><v-form ref="form">
                    <v-container>
                        <v-row>

                            <!-- Subtitle 1 -->
                            <v-col>
                                <div class="text-subtitle-1 text-bold">
                                    General Information
                                </div>
                            </v-col>
                            <!-- Input Type -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    readonly
                                    :prepend-inner-icon="objData.iconType"
                                    label="Type"
                                    v-model="objData.type"
                                ></v-text-field>
                            </v-col>

                            <!-- Input status -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="statusLabel"
                                label="Status"
                                v-model="objData.statusAsset"
                                ></v-select>
                            </v-col>

                            <!-- Input Brand -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Brand"
                                    v-model="objData.brandAsset"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Model -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Model"
                                    v-model="objData.modelAsset"
                                ></v-text-field>
                            </v-col>

                            <!-- Input Serial Number -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Serial Number *"
                                    v-model="objData.serialNo"
                                ></v-text-field>
                            </v-col>
                            <!-- Input item -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Item"
                                    v-model="objData.itemAccessories"
                                ></v-text-field>
                            </v-col>
                            <!-- Input Asset Tag -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Asset Tag"
                                    v-model="objData.assetTag"
                                ></v-text-field>
                            </v-col>
                            <!-- Select Purchased By -->
                            <!-- Select Purchased By -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="company"
                                item-text="name"
                                item-value="idcompany"
                                label="Purchased By"
                                v-model="objData.idcompany"
                                ></v-select>
                            </v-col>
                            
                            <!-- Select Employee Name -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Employee Name"
                                v-model="objData.idemp"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- Date Purchased -->
                            <v-col cols="12" class="py-0">
                                <v-menu
                                v-model="menuPurchaseDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="objData.purchaseDate"
                                    label="Date Purchased"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="objData.purchaseDate"
                                    @input="menuPurchaseDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date Start -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrStarDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="objData.warrStartDate"
                                    label="Warranty Start"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="objData.warrStartDate"
                                    @input="menuWarrStarDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date End -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrEndDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="objData.warrEndDate"
                                    label="Warranty End"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="objData.warrEndDate"
                                    @input="menuWarrEndDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Select Registered By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                 :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Registered By"
                                v-model="objData.idempRegister"
                                ></v-autocomplete>
                            </v-col>

                            <!-- Select Disposed By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Disposed By"
                                v-model="objData.idempDispose"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- Input Remarks -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Remarks"
                                    v-model="objData.remarksAsset"
                                ></v-text-field>
                            </v-col>

                            <!-- Upload Image -->
                            <v-col>
                                <v-file-input
                                outlined
                                dense
                                @change="uploadPictureAsset($event)"
                                v-model="uploadPic"
                                label="Upload Image (Asset)"
                                prepend-icon="mdi-camera"
                                ></v-file-input>
                                <a v-if="objData.picturePath != ''" :href="objData.picturePath" target="_blank">
                                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                </a>
                                <a v-if="objData.picturePath == ''" style="cursor:default;">
                                    <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                    <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                </a> 
                            </v-col>


                        </v-row>
                    </v-container>
                    </v-form>
                    <small class="blackk--text text-bold">*indicates required field</small><br>
                    <small class="blackk--text text-bold">*All information to be filled in by Asset Inventory Manager</small>
                            </v-card-text>
                            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogAddAccessories = false"
                >
                    Close
                </v-btn>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="postAddInventory(objData.type)"
                >
                    Add
                </v-btn>
                </v-card-actions>
                </v-card>
                
            </v-dialog>
        </template>

        <!-- Dialog Edit Form LAPTOP/DESKTOP/SERVER -->
        <template>
            <v-dialog
            v-model="dialogEditComputer"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>
                <v-card-title class="title-bar-modul">
                <span class="text-h5">EDIT INVENTORY</span><v-icon></v-icon>
                </v-card-title>
                <v-card-text>
                <v-form ref="form">
                    <v-container>
                        <v-row>

                            <!-- Subtitle 1 -->
                            <v-col>
                                <div class="text-subtitle-1 text-bold">
                                    General Information
                                </div>
                            </v-col>
                            
                            <!-- Input Type -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    :prepend-inner-icon="objData.iconType"
                                    label="Type"
                                    readonly
                                    v-model="displayEditComputerGeneralDetails.editType"
                                ></v-text-field>
                            </v-col>

                            <!-- Input status -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="statusLabel"
                                label="Status"
                                v-model="displayEditComputerGeneralDetails.editStatus"
                                ></v-select>
                            </v-col>
                            
                            <!-- Input Brand -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Brand"
                                    v-model="displayEditComputerGeneralDetails.editBrand"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Model -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Model"
                                    v-model="displayEditComputerGeneralDetails.editModel"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Asset Tag -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Asset Tag"
                                    v-model="displayEditComputerGeneralDetails.editTag"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Serial Number -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    readonly
                                    label="Serial Number (Cannot Change)"
                                    v-model="displayEditComputerGeneralDetails.editSerial"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Select Purchased By -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="company"
                                item-text="name"
                                item-value="idcompany"
                                label="Purchased By"
                                v-model="displayEditComputerGeneralDetails.editPurchaserName"
                                ></v-select>
                            </v-col>
                            
                            <!-- Select Employee Name -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Employee Name"
                                v-model="displayEditComputerGeneralDetails.editEmpName"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- Date Purchased -->
                            <v-col cols="12" class="py-0">
                                <v-menu
                                v-model="menuPurchaseDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="displayEditComputerGeneralDetails.editPurchasedDate"
                                    label="Date Purchased"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="displayEditComputerGeneralDetails.editPurchasedDate"
                                    @input="menuPurchaseDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date Start -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrStarDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="displayEditComputerGeneralDetails.editWarrStart"
                                    label="Warranty Start"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="displayEditComputerGeneralDetails.editWarrStart"
                                    @input="menuWarrStarDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date End -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrEndDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="displayEditComputerGeneralDetails.editWarrEnd"
                                    label="Warranty End"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="displayEditComputerGeneralDetails.editWarrEnd"
                                    @input="menuWarrEndDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Select Registered By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                 :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Registered By"
                                v-model="displayEditComputerGeneralDetails.editRegisterarName"
                                ></v-autocomplete>
                            </v-col>

                            <!-- Select Disposed By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Disposed By"
                                v-model="displayEditComputerGeneralDetails.editDisposedBy"
                                ></v-autocomplete>
                            </v-col>

                            <!-- ================================================================================ -->

                            <!-- Subtitle 2 -->
                            <v-col>
                                <div class="text-subtitle-1 text-bold">
                                    Specification
                                </div>
                            </v-col>

                            <!-- Input Processor -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Processor"
                                    v-model="displayEditComputerSpecDetails.editProcessor"
                                ></v-text-field>
                            </v-col>

                            <!-- Input RAM -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="RAM"
                                    v-model="displayEditComputerSpecDetails.editRam"
                                ></v-text-field>
                            </v-col>

                            <!-- Input Storage -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Storage"
                                    v-model="displayEditComputerSpecDetails.editStorage"
                                ></v-text-field>
                            </v-col>

                            <!-- Input MAC Address -->
                            <v-col cols="12" class="py-0">
                                <v-card class="mb-5 pa-2" elevation="4">
                                    <v-container class="mb-4 px-0 pt-0 subtitle-2">
                                        MAC Address
                                    </v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" class="py-0">
                                            <v-text-field
                                                outlined
                                                dense
                                                label="LAN"
                                                v-model="displayEditComputerSpecDetails.editMacLan"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" class="py-0">
                                            <v-text-field
                                                outlined
                                                dense
                                                label="WIFI"
                                                v-model="displayEditComputerSpecDetails.editMacWifi"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                            <!-- Input Remarks -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Remarks"
                                    v-model="displayEditComputerGeneralDetails.editRemarks"
                                ></v-text-field>
                            </v-col>

                            <!-- Upload Image -->
                            <v-col>
                                <v-file-input
                                outlined
                                dense
                                @change="uploadPictureAsset($event)"
                                v-model="uploadPic"
                                label="Upload Image (Asset)"
                                prepend-icon="mdi-camera"
                                ></v-file-input>
                                <a v-if="displayEditComputerGeneralDetails.editPicturePath != ''" :href="displayEditComputerGeneralDetails.editPicturePath" target="_blank">
                                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                </a>
                                <a v-if="displayEditComputerGeneralDetails.editPicturePath == ''" style="cursor:default;">
                                    <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                    <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                </a> 
                            </v-col>

                        </v-row>
                    </v-container>
                </v-form>
                
                <small class="blackk--text text-bold">*indicates required field</small><br>
                <small class="blackk--text text-bold">*All information to be filled in by Asset Inventory Manager</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogEditComputer = false;"
                >
                    Close
                </v-btn>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="putEditInventory(displayEditComputerGeneralDetails,displayEditComputerSpecDetails)"
                >
                    Done
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </template> 

    <!-- Dialog Done Form MONITOR -->
        <template>
            <v-dialog
            v-model="dialogEditMonitor"
            persistent
            scrollable
            max-height="10px"
            max-width="650px">
                <v-card>
                    <v-card-title class="title-bar-modul">
                <span class="text-h5">EDIT INVENTORY</span><v-icon></v-icon>
                </v-card-title>
                <v-card-text><v-form ref="form">
                    <v-container>
                        <v-row>

                            <!-- Subtitle 1 -->
                            <v-col>
                                <div class="text-subtitle-1 text-bold">
                                    General Information
                                </div>
                            </v-col>
                            <!-- Input Type -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    readonly
                                    :prepend-inner-icon="objData.iconType"
                                    label="Type"
                                    v-model="displayEditMonitorGeneralDetails.editType"
                                ></v-text-field>
                            </v-col>

                            <!-- Input status -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="statusLabel"
                                label="Status"
                                v-model="displayEditMonitorGeneralDetails.editStatus"
                                ></v-select>
                            </v-col>

                            <!-- Input Brand -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Brand"
                                    v-model="displayEditMonitorGeneralDetails.editBrand"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Model -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Model"
                                    v-model="displayEditMonitorGeneralDetails.editModel"
                                ></v-text-field>
                            </v-col>

                             <!-- Input Monitor Size -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Monitor Size"
                                    v-model="displayEditMonitorGeneralDetails.editMonitorSize"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Asset Tag -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Asset Tag"
                                    v-model="displayEditMonitorGeneralDetails.editTag"
                                ></v-text-field>
                            </v-col>

                            <!-- Input Serial Number -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    readonly
                                    label="Serial Number (Cannot Change)"
                                    v-model="displayEditMonitorGeneralDetails.editSerial"
                                ></v-text-field>
                            </v-col>
                            <!-- Select Purchased By -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="company"
                                item-text="name"
                                item-value="idcompany"
                                label="Purchased By"
                                v-model="displayEditMonitorGeneralDetails.editPurchaserName"
                                return-object
                                ></v-select>
                            </v-col>
                            
                            <!-- Select Employee Name -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Employee Name"
                                v-model="displayEditMonitorGeneralDetails.editEmpName"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- Date Purchased -->
                            <v-col cols="12" class="py-0">
                                <v-menu
                                v-model="menuPurchaseDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="displayEditMonitorGeneralDetails.editPurchasedDate"
                                    label="Date Purchased"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="displayEditMonitorGeneralDetails.editPurchasedDate"
                                    @input="menuPurchaseDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date Start -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrStarDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="displayEditMonitorGeneralDetails.editWarrStart"
                                    label="Warranty Start"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="displayEditMonitorGeneralDetails.editWarrStart"
                                    @input="menuWarrStarDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date End -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrEndDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="displayEditMonitorGeneralDetails.editWarrEnd"
                                    label="Warranty End"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="displayEditMonitorGeneralDetails.editWarrEnd"
                                    @input="menuWarrEndDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Select Registered By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Registered By"
                                v-model="displayEditMonitorGeneralDetails.editRegisterarName"
                                ></v-autocomplete>
                            </v-col>

                            <!-- Select Disposed By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                               :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Disposed By"
                                v-model="displayEditMonitorGeneralDetails.editDisposedBy"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- Input Remarks -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Remarks"
                                    v-model="displayEditMonitorGeneralDetails.editRemarks"
                                ></v-text-field>
                            </v-col>

                            <!-- Upload Image -->
                            <v-col>
                                <v-file-input
                                outlined
                                dense
                                @change="uploadPictureAsset($event)"
                                v-model="uploadPic"
                                label="Upload Image (Asset)"
                                prepend-icon="mdi-camera"
                                ></v-file-input>
                                <a v-if="displayEditMonitorGeneralDetails.editPicturePath != ''" :href="displayEditMonitorGeneralDetails.editPicturePath" target="_blank">
                                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                </a>
                                <a v-if="displayEditMonitorGeneralDetails.editPicturePath == ''" style="cursor:default;">
                                    <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                    <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                </a> 
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-form>
                    <small class="blackk--text text-bold">*indicates required field</small><br>
                    <small class="blackk--text text-bold">*All information to be filled in by Asset Inventory Manager</small>
                            </v-card-text>
                            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogEditMonitor = false; "
                >
                    Close
                </v-btn>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="putEditInventory(displayEditMonitorGeneralDetails,)"
                >
                    Done
                </v-btn>
                </v-card-actions>
                </v-card>
                
            </v-dialog>
        </template>

        <!-- Dialog Edit Form ACCESSORIES -->
        <template>
            <v-dialog
            v-model="dialogEditAccessories"
            persistent
            scrollable
            max-height="10px"
            max-width="650px">
                <v-card>
                    <v-card-title class="title-bar-modul">
                <span class="text-h5">EDIT INVENTORY</span><v-icon></v-icon>
                </v-card-title>
                <v-card-text><v-form ref="form">
                    <v-container>
                        <v-row>

                            <!-- Subtitle 1 -->
                            <v-col>
                                <div class="text-subtitle-1 text-bold">
                                    General Information
                                </div>
                            </v-col>
                            <!-- Input Type -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    :prepend-inner-icon="objData.iconType"
                                    label="Type"
                                    readonly
                                    v-model="displayEditAccessoriesGeneralDetails.editType"
                                ></v-text-field>
                            </v-col>

                            <!-- Input status -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="statusLabel"
                                label="Status"
                                v-model="displayEditAccessoriesGeneralDetails.editStatus"
                                ></v-select>
                            </v-col>

                            <!-- Input Brand -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Brand"
                                    v-model="displayEditAccessoriesGeneralDetails.editBrand"
                                ></v-text-field>
                            </v-col>
                            
                            <!-- Input Model -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Model"
                                    v-model="displayEditAccessoriesGeneralDetails.editModel"
                                ></v-text-field>
                            </v-col>

                            <!-- Input Serial Number -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    readonly
                                    label="Serial Number (Cannot Change)"
                                    v-model="displayEditAccessoriesGeneralDetails.editSerial"
                                ></v-text-field>
                            </v-col>
                            <!-- Input item -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Item"
                                    v-model="displayEditAccessoriesGeneralDetails.editItem"
                                ></v-text-field>
                            </v-col>
                            <!-- Input Asset Tag -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Asset Tag"
                                    v-model="displayEditAccessoriesGeneralDetails.editTag"
                                ></v-text-field>
                            </v-col>
                            <!-- Select Purchased By -->
                            <!-- Select Purchased By -->
                            <v-col cols="12" class="py-0">
                                <v-select
                                outlined
                                dense
                                :items="company"
                                item-text="name"
                                item-value="idcompany"
                                label="Purchased By"
                                v-model="displayEditAccessoriesGeneralDetails.editPurchaserName"
                             return-object
                                ></v-select>
                            </v-col>
                            
                            <!-- Select Employee Name -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Employee Name"
                                v-model="displayEditAccessoriesGeneralDetails.editEmpName"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- Date Purchased -->
                            <v-col cols="12" class="py-0">
                                <v-menu
                                v-model="menuPurchaseDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="displayEditAccessoriesGeneralDetails.editPurchasedDate"
                                    label="Date Purchased"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="displayEditAccessoriesGeneralDetails.editPurchasedDate"
                                    @input="menuPurchaseDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date Start -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrStarDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="displayEditAccessoriesGeneralDetails.editWarrStart"
                                    label="Warranty Start"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="displayEditAccessoriesGeneralDetails.editWarrStart"
                                    @input="menuWarrStarDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Warranty Date End -->
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu
                                v-model="menuWarrEndDt"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    outlined
                                    dense
                                    v-model="displayEditAccessoriesGeneralDetails.editWarrEnd"
                                    label="Warranty End"
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="displayEditAccessoriesGeneralDetails.editWarrEnd"
                                    @input="menuWarrEndDt = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <!-- Select Registered By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                 :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Registered By"
                                v-model="displayEditAccessoriesGeneralDetails.editRegisterarName"
                                ></v-autocomplete>
                            </v-col>

                            <!-- Select Disposed By -->
                            <v-col cols="12" class="py-0">
                                <v-autocomplete
                                outlined
                                dense
                                prepend-inner-icon="mdi-account"
                                :items="empDetails"
                                item-text="empName"
                                item-value="empId"
                                label="Disposed By"
                                v-model="displayEditAccessoriesGeneralDetails.editDisposedBy"
                                ></v-autocomplete>
                            </v-col>
                            
                            <!-- Input Remarks -->
                            <v-col cols="12" class="py-0">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Remarks"
                                    v-model="displayEditAccessoriesGeneralDetails.editRemarks"
                                ></v-text-field>
                            </v-col>

                            <!-- Upload Image -->
                            <v-col>
                                <v-file-input
                                outlined
                                dense
                                @change="uploadPictureAsset($event)"
                                v-model="uploadPic"
                                label="Upload Image (Asset)"
                                prepend-icon="mdi-camera"
                                ></v-file-input>
                                <a v-if="displayEditAccessoriesGeneralDetails.editPicturePath != ''" :href="displayEditAccessoriesGeneralDetails.editPicturePath" target="_blank">
                                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                </a>
                                <a v-if="displayEditAccessoriesGeneralDetails.editPicturePath == ''" style="cursor:default;">
                                    <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                    <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                </a> 
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-form>
                    <small class="blackk--text text-bold">*indicates required field</small><br>
                    <small class="blackk--text text-bold">*All information to be filled in by Asset Inventory Manager</small>
                            </v-card-text>
                            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogEditAccessories = false; "
                >
                    Close
                </v-btn>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="putEditInventory(displayEditAccessoriesGeneralDetails,)"
                >
                    Done
                </v-btn>
                </v-card-actions>
                </v-card>
                
            </v-dialog>
        </template>

        <!-- Popup Computer Details -->
        <template>
            <v-dialog
            v-model="dialogComputerDisplayDetails"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Inventory Detail</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                <a :href="displayImage" target="_blank">
                     <img :src="displayImage? displayImage : nullImage.src" style="display: block;
                        margin-left: auto;
                        margin-right: auto;
                        width: 80%;
                        ">
                </a>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <v-col>
                            <div style="white-space: nowrap;" class="text-subtitle-1 text-bold grey--text">
                                General Information
                            </div>
                        </v-col>
                        
                        <tr
                        v-for="item in displayGeneralDetails"
                        :key="item.name"
                        >
                            <td class="font-weight-bold"><v-icon class="icon-text blackIcon--text" small>{{ item.icon }}</v-icon>{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                        </tr>
                        <v-col>
                                <div class="text-subtitle-1 text-bold grey--text">
                                   Specification
                                </div>
                            </v-col>
                        <tr
                        v-for="item in displaySpecDetails"
                        :key="item.name"
                        >
                            <td class="font-weight-bold"><v-icon class="icon-text blackIcon--text" small>{{ item.icon }}</v-icon>{{ item.key }}</td>
                            <td v-if="item.value != 'PENDING' && item.value != 'APPROVE'">{{ item.value }}</td>
                            <td v-if="item.value == 'PENDING'">
                                <v-chip
                                dense
                                color="pending"
                                class="blackk--text text-bold"
                                >
                                {{ item.value }}
                                </v-chip>
                            </td>
                            <td v-if="item.value == 'APPROVE'">
                                <v-chip
                                dense
                                color="success"
                                class="white--text text-bold"
                                >
                                {{ item.value }}
                                </v-chip>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogComputerDisplayDetails = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

        <!-- Popup Monitor Details -->
        <template>
            <v-dialog
            v-model="dialogMonitorDisplayDetails"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Inventory Detail</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                     <a :href="displayImage" target="_blank">
                        <img :src="displayImage? displayImage : nullImage.src" style="display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 80%;
                            ">
                    </a>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <v-col>
                            <div style="white-space: nowrap;" class="text-subtitle-1 text-bold grey--text">
                                General Information
                            </div>
                        </v-col>
                        
                        <tr
                        v-for="item in displayMonitorGeneralDetails"
                        :key="item.name"
                        >
                            <td class="font-weight-bold"><v-icon class="icon-text blackIcon--text" small>{{ item.icon }}</v-icon>{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogMonitorDisplayDetails = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

        <!-- Popup Accessories Details -->
        <template>
            <v-dialog
            v-model="dialogAccessoriesDisplayDetails"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>

                <v-card-title class="title-bar-modul">
                <span class="text-h5">Inventory Detail</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                    <a :href="displayImage" target="_blank">
                        <img :src="displayImage? displayImage : nullImage.src" style="display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 80%;
                            ">
                    </a>
                <v-simple-table class="table-details-info">
                    <template v-slot:default>
                    <tbody>
                        <v-col>
                            <div style="white-space: nowrap;" class="text-subtitle-1 text-bold grey--text">
                                General Information
                            </div>
                        </v-col>
                        
                        <tr
                        v-for="item in displayAccessoriesGeneralDetails"
                        :key="item.name"
                        >
                            <td class="font-weight-bold"><v-icon class="icon-text blackIcon--text" small>{{ item.icon }}</v-icon>{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogAccessoriesDisplayDetails = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            
            </v-card>
            </v-dialog>
        </template>

        <!-- Popup Delete Confirmation -->
        <template >
        <template>
            <v-dialog
            v-model="dialogDeleteConfirmation"
            persistent
            scrollable
            max-height="10px"
            max-width="650px">
            <v-card>
                <v-card-title>
                <span class="text-h5">Are you sure want to delete this item?</span><v-icon></v-icon>
                 </v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="dialogDeleteConfirmation = false"
                >
                    Close
                </v-btn>
                <v-btn
                class="text-bold"
                color="primary"
                text
                @click="getDeleteInventory()"
                >
                    Confirm
                </v-btn>
                </v-card-actions>
               
            </v-card>
            </v-dialog>
        </template>
        </template>

        <!-- Success Adding Asset -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogSuccess"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 success--text">
                        <v-icon class="icon-text success--text">mdi-check-circle</v-icon>
                        Success!
                    </v-card-title>
                    <v-card-text class="blackk--text">Asset is succesfully {{textSuccess}} in system.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogSuccess = false;  reloadPage()"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Error -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogError"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                        Failed!
                    </v-card-title>
                    <v-card-text class="blackk--text">Something went wrong. Please make sure all required form is filled, and try again.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogError = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>

        <!-- Warning Blank Field Form -->
        <template>
            <v-row justify="center">
                <v-dialog
                v-model="dialogWarningBlankInput"
                persistent
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5 red--text">
                        <v-icon class="icon-text red--text">mdi-alert-circle</v-icon>
                        Warning!
                    </v-card-title>
                    <v-card-text class="blackk--text">Please fill out Serial Number field.</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogWarningBlankInput = false"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </template>
      


    </v-container>
</template>

<script>
import axios from 'axios';
import Papa from "papaparse";

export default {
    

    data: () => ({
        CheckLoadingTable: true,
        searchDtAsset: '',
        textSuccess: '',
        headers: [],
        allData: [],
        searchObj: {},
        multiSearch: [],
        statusLaptopAvailableCount: 0,
        statusDesktopAvailableCount: 0,
        statusServerAvailableCount: 0,
        statusMonitorAvailableCount: 0,
        statusAccessoriesAvailableCount: 0,

        statusLaptopNotAvailableCount: 0,
        statusDesktopNotAvailableCount: 0,
        statusServerNotAvailableCount: 0,
        statusMonitorNotAvailableCount: 0,
        statusAccessoriesNotAvailableCount: 0,

        empIdDel:"",
        noSeriesDel:"",
        editedIndex: null,

        pointData:[],
        uploadPic: null,

        statusLabel: ["AVAILABLE", "NOT AVAILABLE"],

        headerAsset: [
            {text: "Type", value: "type"},
            {text: "Brand", value: "brandAsset"},
            {text: "Model", value: "modelAsset"},
            {text: "Serial No.", value: "serialNo"},
            {text: "Tag No.", value: "assetTag"},
            {text: "Date Purchased", value: "purchaseDate"},
            {text: "Status", value: "statusAsset"},
            {text: "Detail", value: "detailsAll"},
        ],
        bodyAsset: [],
        displayImage: "",
        nullImage: {
            src: require('@/assets/img/meetingRooms/none-image.jpg')
        },


        displayGeneralDetails: [
            {key: "Type", value: "", icon: "mdi-pin-outline"},    
            {key: "Employee Name", value: "", icon: "mdi-account"},  
            {key: "Brand", value: "", icon: "mdi-watermark"},    
            {key: "Asset Tag", value: "", icon: "mdi-label"}, 
            {key: "Serial Number", value: "", icon: "mdi-numeric"},    
            {key: "Model", value: "", icon: "mdi-globe-model"}, 
            // {key: "Monitor Size", value: "", icon: "mdi-monitor-screenshot"},       
            {key: "Warranty Start Date", value: "", icon: "mdi-calendar-start"},    
            {key: "Warranty End Date", value: "", icon: "mdi-calendar-end"},    
            {key: "Purchased By", value: "", icon: "mdi-account-arrow-right"},
            {key: "Purchased Date", value: "", icon: "mdi-calendar-outline"},    
            {key: "Disposed By", value: "", icon: "mdi-account-alert"},  
            {key: "Registered By", value: "", icon: "mdi-account-details"}, 
            {key: "Remarks", value: "", icon: "mdi-bookmark-check-outline"}, 
            {key: "Status", value: "", icon: "mdi-check-decagram-outline"},
        ],

        displaySpecDetails: [
            {key: "Processor", value: "", icon: "mdi-memory"},    
            {key: "Mac LAN", value: "", icon: "mdi-lan"},    
            {key: "Mac WiFi", value: "", icon: "mdi-wifi"},    
            {key: "RAM", value: "", icon: "mdi-camera-metering-partial"},    
            {key: "Storage", value: "", icon: "mdi-database"},  
        ],

        displayMonitorGeneralDetails: [
            {key: "Type", value: "", icon: "mdi-pin-outline"},    
            {key: "Employee Name", value: "", icon: "mdi-account"},  
            {key: "Brand", value: "", icon: "mdi-watermark"},    
            {key: "Asset Tag", value: "", icon: "mdi-label"}, 
            {key: "Serial Number", value: "", icon: "mdi-numeric"},    
            {key: "Model", value: "", icon: "mdi-globe-model"}, 
            {key: "Monitor Size", value: "", icon: "mdi-monitor-screenshot"},       
            {key: "Warranty Start Date", value: "", icon: "mdi-calendar-start"},    
            {key: "Warranty End Date", value: "", icon: "mdi-calendar-end"},    
            {key: "Purchased By", value: "", icon: "mdi-account-arrow-right"},
            {key: "Purchased Date", value: "", icon: "mdi-calendar-outline"},    
            {key: "Disposed By", value: "", icon: "mdi-account-alert"},  
            {key: "Registered By", value: "", icon: "mdi-account-details"}, 
            {key: "Remarks", value: "", icon: "mdi-bookmark-check-outline"}, 
            {key: "Status", value: "", icon: "mdi-check-decagram-outline"},
        ],

        displayAccessoriesGeneralDetails: [
            {key: "Type", value: "", icon: "mdi-pin-outline"},    
            {key: "Employee Name", value: "", icon: "mdi-account"},  
            {key: "Brand", value: "", icon: "mdi-watermark"},
            {key: "Item", value: "", icon: "mdi-mouse-variant"},
            {key: "Asset Tag", value: "", icon: "mdi-label"}, 
            {key: "Serial Number", value: "", icon: "mdi-numeric"},    
            {key: "Model", value: "", icon: "mdi-globe-model"}, 
            // {key: "Monitor Size", value: "", icon: "mdi-monitor-screenshot"},       
            {key: "Warranty Start Date", value: "", icon: "mdi-calendar-start"},    
            {key: "Warranty End Date", value: "", icon: "mdi-calendar-end"},    
            {key: "Purchased By", value: "", icon: "mdi-account-arrow-right"},
            {key: "Purchased Date", value: "", icon: "mdi-calendar-outline"},    
            {key: "Disposed By", value: "", icon: "mdi-account-alert"},  
            {key: "Registered By", value: "", icon: "mdi-account-details"}, 
            {key: "Remarks", value: "", icon: "mdi-bookmark-check-outline"}, 
            {key: "Status", value: "", icon: "mdi-check-decagram-outline"},
        ],
        dialogDisplayDetails: false,

        dataForExport: [],

        // Dialog Add Section
        dialogAddComputer: false,
        dialogAddMonitor: false,
        dialogAddAccessories: false,

         // Dialog Edit Section
        dialogEditComputer: false,
        dialogEditMonitor: false,
        dialogEditAccessories: false,

        // Popup Section
        dialogComputerDisplayDetails: false,
        dialogMonitorDisplayDetails: false,
        dialogAccessoriesDisplayDetails: false,

        // Delete Dialog
        dialogDeleteConfirmation: false,

        dialogSuccess: false,
        dialogError: false,
        dialogWarningBlankInput: false,
        // dialogSuccessEditAsset: false,


        // Form Section
        // --Laptop/Desktop/Server--
        objData: {
            type: "",
            iconType:"",
            brandAsset: "",
            modelAsset: "",
            assetTag: "",
            serialNo: "",
            idcompany: "",
            idassettype: "",
            name:"",
            empName: "",
            purchaseDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            warrStartDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            warrEndDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            registrarName: "",
            disposedBy: "",
            processorAsset: "",
            ramAsset: "",
            storageAsset: "",
            macLan: "",
            macWifi: "",
            remarksAsset: "",
            monitorSize: 0,
            purchaserName:"",
            idemp:"",
            idempRegister: "",
            idempDispose: "",
            itemAccessories:"",
            picturePath: ""
        },

        // Form Edit Section
        displayEditComputerGeneralDetails: [
            {
                editType: "",
                editEmpName: {},
                editBrand:"",
                editTag: "",
                editSerial: "",
                editModel: "",
                editWarrStart: "",
                editWarrEnd: "",
                editPurchaserName: {},
                editPurchasedDate: "",
                editDisposedBy: {},
                editRegisterarName: {},
                editRemarks: "",
                editStatus: "",
                editPicturePath: ""
            },    
        ],

        displayEditComputerSpecDetails: [
            {
                editProcessor: "",
                editMacLan: "",
                editMacWifi: "",
                editRam: "",
                editStorage: ""
            }
        ],

        displayEditMonitorGeneralDetails: [
            {
                editType: "",
                editEmpName: {},
                editBrand:"",
                editMonitorSize: 0,
                editTag: "",
                editSerial: "",
                editModel: "",
                editWarrStart: "",
                editWarrEnd: "",
                editPurchaserName: {},
                editPurchasedDate: "",
                editDisposedBy: {},
                editRegisterarName: {},
                editRemarks: "",
                editStatus: "",
                editPicturePath: ""
            },    
        ],

        displayEditAccessoriesGeneralDetails: [
            {
                editType: "",
                editEmpName: "",
                editBrand:"",
                editItem: "",
                editTag: "",
                editSerial: "",
                editModel: "",
                editWarrStart: "",
                editWarrEnd: "",
                editPurchaserName: "",
                editPurchasedDate: "",
                editDisposedBy: "",
                editRegisterarName: "",
                editRemarks: "",
                editStatus: "",
                editPicturePath: ""
            },    
        ],


        // Engine Runner Vuetify Form
        company: [],
        assettype: [],
        empDetails: [],
        staffName: [
            "SYAFIQ",
            "SHAZ",
            "ALI",
            "AMIRUL",
            "FIRDAUS",
        ],
        menuPurchaseDt: false,
        menuWarrStarDt: false,
        menuWarrEndDt: false,
        

    }),
    methods: {

        // Data Table Section
        loadDataTable() {
            this.CheckLoadingTable = true;
            this.allData = []
            this.headers = this.headerCarBooking
            // let userName = this.$store.getters.user.name;

             axios.get(process.env.VUE_APP_API_URL + 'asset/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                let data = response.data
                for(let i in data){
                    (data[i]["asset_tag"] == null || data[i]["asset_tag"] == "") ? data[i]["assetTag"] = "N/A" : data[i]["assetTag"] = data[i]["asset_tag"];
                    data[i]["type"] = data[i]["assetname"];
                    (data[i]["brand"] == null || data[i]["brand"] == "") ? data[i]["brandAsset"] = "N/A" : data[i]["brandAsset"] = data[i]["brand"];
                    (data[i]["date_purchased"] == null || data[i]["date_purchased"] == "") ? data[i]["purchaseDate"] = "N/A" : data[i]["purchaseDate"] = this.convertDateString(data[i]["date_purchased"]);
                    data[i]["disposedBy"] = data[i]["disposed_by"];
                    data[i]["itemAccessories"] = data[i]["item"];
                    data[i]["empName"] = data[i]["empname"];
                    data[i]["warrEndDate"] = this.convertDateString(data[i]["end_date_warranty"]);
                    data[i]["macLan"] = data[i]["mac_address_lan"];
                    data[i]["macWifi"] = data[i]["mac_address_wifi"];
                    (data[i]["model"] == null || data[i]["model"] == "") ? data[i]["modelAsset"] = "N/A" : data[i]["modelAsset"] = data[i]["model"];
                    data[i]["processorAsset"] = data[i]["processor"];
                    data[i]["purchaserName"] = data[i]["purchaser_name"];
                    data[i]["ramAsset"] = data[i]["ram"];
                    data[i]["registrarName"] = data[i]["registrar_name"];
                    data[i]["remarksAsset"] = data[i]["remarks"];
                    data[i]["serialNo"] = data[i]["serial_no"];
                    data[i]["warrStartDate"] = this.convertDateString(data[i]["start_date_warranty"]);
                    data[i]["monitorSize"] = data[i]["monitor_size"];
                    data[i]["statusAsset"] = data[i]["status"];
                    data[i]["storageAsset"] = data[i]["storage"];
                    data[i]["detailsAll"] = "mdi-file-document";
                    data[i]["displayImage"] = data[i]["picture"];
                }
              
                 for (let i = 0; i < data.length; i++) {
                        this.bodyAsset.push(data[i]);
                        if(data[i].type == "Laptop" && data[i].statusAsset == "AVAILABLE") {
                            this.statusLaptopAvailableCount += 1
                        } else if (data[i].type == "Desktop" && data[i].statusAsset == "AVAILABLE"){
                            this.statusDesktopAvailableCount += 1
                        } else if (data[i].type == "Server" && data[i].statusAsset == "AVAILABLE"){
                            this.statusServerAvailableCount += 1
                        } else if (data[i].type == "Monitor" && data[i].statusAsset == "AVAILABLE"){
                            this.statusMonitorAvailableCount += 1
                        } else if (data[i].type == "Accessories" && data[i].statusAsset == "AVAILABLE"){
                            this.statusAccessoriesAvailableCount += 1
                        } 
                        
                          else if(data[i].type == "Laptop" && data[i].statusAsset == "NOT AVAILABLE") {
                            this.statusLaptopNotAvailableCount += 1
                        } else if (data[i].type == "Desktop" && data[i].statusAsset == "NOT AVAILABLE"){
                            this.statusDesktopNotAvailableCount += 1
                        } else if (data[i].type == "Server" && data[i].statusAsset == "NOT AVAILABLE"){
                            this.statusServerNotAvailableCount += 1
                        } else if (data[i].type == "Monitor" && data[i].statusAsset == "NOT AVAILABLE"){
                            this.statusMonitorNotAvailableCount += 1
                        } else if (data[i].type == "Accessories" && data[i].statusAsset == "NOT AVAILABLE"){
                            this.statusAccessoriesNotAvailableCount += 1
                        }
                        
                }
                this.allData = this.bodyAsset
                this.headers = this.headerAsset

                this.getDropDown();
                this.getAllEmp();

                this.CheckLoadingTable = false;
            })
            .catch(error => {
                console.log(error);
            })           
        },

        controlColorFont(status){
            if (status == "AVAILABLE") {
                return "white--text text-bold" 
            }
            else if(status == "NOT AVAILABLE"){
                return "white--text text-bold" 
            }
        },

        controlBgColor(status){
            if (status == "AVAILABLE") {
                return "green" 
            }
            else if(status == "NOT AVAILABLE"){
                return "red" 
            }
        },

         dialogEditInventory(item){
            if(item.type=="Laptop" ||item.type=="Desktop" ||item.type=="Server") {
                
                this.displayEditComputerGeneralDetails.editType = item.type;
                this.displayEditComputerGeneralDetails.editEmpName = {empName: item.empName, empId: item.idemp};
                this.displayEditComputerGeneralDetails.editBrand = item.brandAsset;
                this.displayEditComputerGeneralDetails.editTag = item.assetTag;
                this.displayEditComputerGeneralDetails.editSerial = item.serialNo;
                this.displayEditComputerGeneralDetails.editModel = item.modelAsset;
                this.displayEditComputerGeneralDetails.editWarrStart = this.convertToCalenderDate(item.warrStartDate);
                this.displayEditComputerGeneralDetails.editWarrEnd = this.convertToCalenderDate(item.warrEndDate);
                this.displayEditComputerGeneralDetails.editPurchaserName = {idcompany: item.purchased_by, name: item.purchaser_name};
                this.displayEditComputerGeneralDetails.editPurchasedDate = this.convertToCalenderDate(item.purchaseDate);
                this.displayEditComputerGeneralDetails.editDisposedBy = {empName: item.disposed_name, empId: item.disposed_by};
                this.displayEditComputerGeneralDetails.editRegisterarName = {empName: item.registrar_name, empId: item.register_by};
                this.displayEditComputerGeneralDetails.editRemarks = item.remarksAsset;
                this.displayEditComputerGeneralDetails.editStatus = item.statusAsset;
                this.displayEditComputerGeneralDetails.editPicturePath = item.picture;
                
                this.displayEditComputerSpecDetails.editProcessor = item.processorAsset;
                this.displayEditComputerSpecDetails.editMacLan = item.macLan;
                this.displayEditComputerSpecDetails.editMacWifi = item.macWifi;
                this.displayEditComputerSpecDetails.editRam = item.ramAsset;
                this.displayEditComputerSpecDetails.editStorage = item.storageAsset;

                this.dialogEditComputer = true
            }

            else if(item.type=="Monitor") {               
                this.displayEditMonitorGeneralDetails.editType = item.type;
                this.displayEditMonitorGeneralDetails.editEmpName = {empName: item.empName, empId: item.idemp};
                this.displayEditMonitorGeneralDetails.editBrand = item.brandAsset;
                this.displayEditMonitorGeneralDetails.editMonitorSize = item.monitor_size;
                this.displayEditMonitorGeneralDetails.editTag = item.assetTag;
                this.displayEditMonitorGeneralDetails.editSerial = item.serialNo;
                this.displayEditMonitorGeneralDetails.editModel = item.modelAsset;
                this.displayEditMonitorGeneralDetails.editWarrStart = new Date(item.warrStartDate).toLocaleString('en-GB');
                this.displayEditMonitorGeneralDetails.editWarrEnd = new Date(item.warrEndDate).toLocaleString('en-GB');
                this.displayEditMonitorGeneralDetails.editPurchaserName = {idcompany: item.purchased_by, name: item.purchaser_name};
                this.displayEditMonitorGeneralDetails.editPurchasedDate = new Date(item.purchaseDate).toLocaleString('en-GB');
                this.displayEditMonitorGeneralDetails.editDisposedBy = {empName: item.disposed_name, empId: item.disposed_by};
                this.displayEditMonitorGeneralDetails.editRegisterarName = {empName: item.registrar_name, empId: item.register_by};
                this.displayEditMonitorGeneralDetails.editRemarks = item.remarksAsset;
                this.displayEditMonitorGeneralDetails.editStatus = item.statusAsset;
                this.displayEditMonitorGeneralDetails.editPicturePath = item.picture;

                this.dialogEditMonitor = true
            }

            else if(item.type=="Accessories") {

                this.displayEditAccessoriesGeneralDetails.editType = item.type;
                this.displayEditAccessoriesGeneralDetails.editEmpName = {empName: item.empName, empId: item.idemp};
                this.displayEditAccessoriesGeneralDetails.editBrand = item.brandAsset;
                this.displayEditAccessoriesGeneralDetails.editItem = item.itemAccessories;
                this.displayEditAccessoriesGeneralDetails.editTag = item.assetTag;
                this.displayEditAccessoriesGeneralDetails.editSerial = item.serialNo;
                this.displayEditAccessoriesGeneralDetails.editModel = item.modelAsset;
                this.displayEditAccessoriesGeneralDetails.editWarrStart = new Date(item.warrStartDate).toLocaleString('en-GB');
                this.displayEditAccessoriesGeneralDetails.editWarrEnd = new Date(item.warrEndDate).toLocaleString('en-GB');
                this.displayEditAccessoriesGeneralDetails.editPurchaserName = {idcompany: item.purchased_by, name: item.purchaser_name};
                this.displayEditAccessoriesGeneralDetails.editPurchasedDate = new Date(item.purchaseDate).toLocaleString('en-GB');
                this.displayEditAccessoriesGeneralDetails.editDisposedBy = {empName: item.disposed_name, empId: item.disposed_by};
                this.displayEditAccessoriesGeneralDetails.editRegisterarName = {empName: item.registrar_name, empId: item.register_by};
                this.displayEditAccessoriesGeneralDetails.editRemarks = item.remarksAsset;
                this.displayEditAccessoriesGeneralDetails.editStatus = item.statusAsset;
                this.displayEditAccessoriesGeneralDetails.editPicturePath = item.picture;

                this.dialogEditAccessories = true
            }

        },

        dialogDeleteInventory(empId, noSeries) {
            this.dialogDeleteConfirmation = true;
            this.empIdDel = empId;
            this.noSeriesDel = noSeries;
        },

         exportExcel(){

            let keys = [
                
                "assetTag",
                    "type",
                    "brandAsset",
                    "purchaseDate",
                    "disposedBy",
                    "itemAccessories",
                    "empName",
                    "warrEndDate",
                    "macLan",
                    "macWifi",
                    "modelAsset",
                    "processorAsset",
                    "purchaserName",
                    "ramAsset",
                    "registrarName",
                    "remarksAsset",
                    "serialNo",
                    "warrStartDate",
                    "monitorSize",
                    "statusAsset",
                    "storageAsset",
               
            ],
            resultAfterFilter = this.dataForExport.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

            let blob = new Blob([Papa.unparse(resultAfterFilter)], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");

            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'List of Inventory.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
         },

        getDeleteInventory() {
            axios
                .get(process.env.VUE_APP_API_URL + 'asset/delasset?serialno=' + this.noSeriesDel, {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.token,
                },
            })
            .then((response) => {//eslint-disable-line
            // console.log(response);
            this.dialogDeleteConfirmation = false;
            this.reloadPage();
            })
            .catch((error) => {
            console.log(error);
            });
        },

        putEditInventory(itemG, itemS) {
            itemG.push(itemS)
            if(itemG.editType == "Laptop") {
                itemG.editType = 1
            } else if(itemG.editType == "Desktop") {
                itemG.editType = 2
            } else if(itemG.editType == "Server") {
                itemG.editType = 3
            } else if(itemG.editType == "Monitor") {
                itemG.editType = 4
            } else if(itemG.editType == "Accessories") {
                itemG.editType = 5
            } 
            const payload = {
                    "idemp": (itemG.editEmpName === "undefined" ? "" : itemG.editEmpName.empId  ),
                    "idassettype":  (itemG.editType === "undefined" ? "" : itemG.editType),
                    "brand": (itemG.editBrand === "undefined" ? "" : itemG.editBrand),
                    "model": (itemG.editModel === "undefined" ? "" : itemG.editModel),
                    "assettag": (itemG.editTag === "undefined" ? "" : itemG.editTag),
                    "serialno": (itemG.editSerial === "undefined" ? "" : itemG.editSerial),
                    "purchasedby": (itemG.editPurchaserName.idcompany === "undefined" ? "" : itemG.editPurchaserName.idcompany),
                    "datepurchased": (itemG.editPurchasedDate === "undefined" ? "" : itemG.editPurchasedDate),
                    "startdatewarranty": (itemG.editWarrStart === "undefined" ? "" : itemG.editWarrStart),
                    "enddatewarranty": (itemG.editWarrEnd === "undefined" ? "" : itemG.editWarrEnd),
                    "registerby": (itemG.editRegisterarName === "undefined" ? "" : itemG.editRegisterarName.empId),
                    "disposedby": (itemG.editDisposedBy === "undefined" ? "" : itemG.editDisposedBy.empId),
                    "processor": (itemS.editProcessor === "undefined" ? "" : itemS.editProcessor),
                    "ram": (itemS.editRam === "undefined" ? "" : itemS.editRam),
                    "storage": (itemS.editStorage === "undefined" ? "" : itemS.editStorage),
                    "macaddresslan": (itemS.editMacLan === "undefined" ? "" : itemS.editMacLan),
                    "macaddresswifi": (itemS.editMacWifi === "undefined" ? "" : itemS.editMacWifi),
                    "monitorsize": (itemG.editMonitorSize === "undefined" ? "" : itemG.editMonitorSize),
                    "item": (itemG.editItem === "undefined" ? "" : itemG.editItem),
                    "status": (itemG.editStatus === "undefined" ? "" : itemG.editStatus),
                    "remarks": (itemG.editRemarks === "undefined" ? "" : itemG.editRemarks),
                    "picture": (itemG.editPicturePath === "undefined" ? "" : itemG.editPicturePath)
            };

            axios
                .put(process.env.VUE_APP_API_URL + 'asset/editasset', payload, {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.token,
                },
            })
            .then((response) => {//eslint-disable-line
            // console.log(response);
            this.dialogAddComputer = false
            this.dialogAddMonitor = false
            this.dialogAddAccessories = false
            this.textSuccess = "edited"
            this.dialogSuccess = true;
            })
            .catch((error) => {
            console.log(error);
            this.dialogSuccess = false;
            this.dialogError = true;
            });


        },

        postAddInventory(item) {
            if(this.objData.serialNo != "") {
                if(item == "Laptop") {
                    this.objData.idassettype = 1
                } else if(item == "Desktop") {
                    this.objData.idassettype = 2
                } else if(item == "Server") {
                    this.objData.idassettype = 3
                } else if(item == "Monitor") {
                    this.objData.idassettype = 4
                } else if(item == "Accessories") {
                    this.objData.idassettype = 5
                } 
                const payload = {
                        "idemp": this.objData.idemp,
                        "idassettype":  this.objData.idassettype,
                        "brand": this.objData.brandAsset,
                        "model": this.objData.modelAsset,
                        "assettag": this.objData.assetTag,
                        "serialno": this.objData.serialNo,
                        "purchasedby": this.objData.idcompany,
                        "datepurchased": this.objData.purchaseDate,
                        "startdatewarranty": this.objData.warrStartDate,
                        "enddatewarranty": this.objData.warrEndDate,
                        "registerby": this.objData.idempRegister,
                        "disposedby": this.objData.idempDispose,
                        "processor": this.objData.processorAsset,
                        "ram": this.objData.ramAsset,
                        "storage": this.objData.storageAsset,
                        "macaddresslan": this.objData.macLan,
                        "macaddresswifi":this.objData.macWifi,
                        "monitorsize": this.objData.monitorSize,
                        "item": this.objData.itemAccessories,
                        "status": this.objData.statusAsset,
                        "remarks": this.objData.remarksAsset,
                        "picture": this.objData.picturePath
                };

                axios
                    .post(process.env.VUE_APP_API_URL + 'asset/addasset', payload, {
                    headers: {
                        Authorization: "Bearer " + this.$store.getters.user.token,
                    },
                })
                .then((response) => {//eslint-disable-line
                // console.log(response);
                this.dialogAddComputer = false
                this.dialogAddMonitor = false
                this.dialogAddAccessories = false
                this.textSuccess = "added"
                this.dialogSuccess = true;
                })
                .catch((error) => {
                console.log(error);
                this.dialogSuccess = false;
                this.dialogError = true;
                });
            } else {
                this.dialogWarningBlankInput = true
            }
            
        },

        getDropDown(){
        axios.get(process.env.VUE_APP_API_URL + 'employees/alldropdowns', {
                  headers: {
                      'Authorization': 'Bearer ' + this.$store.getters.token,
                  }
              })
              .then((response) => {
                  this.company = response.data.company
              })
              .catch(error => {
                  console.log(error);
              })

        },

        getAllEmp(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/all', {
                  headers: {
                      'Authorization': 'Bearer ' + this.$store.getters.token,
                  }
              })
              .then((response) => {
                  let data = response.data

                  for (let i = 0; i < data.length; i++) {
                      this.empDetails.push({empName: data[i].name, empId: data[i].idemp})
                  }
              })
              .catch(error => {
                  console.log(error);
              })

        },

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);
            this.dataForExport = items;
            
            items.sort((a, b) => {
                if (index[0]=='dtApplied' || index[0]=='startDateTime' || index[0]=='endDateTime') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                    if (!isDesc[0]) {
                        return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                    }
                    else {
                        return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                    }
                    }
                }
            });
            return items;
        },




        isDisable(index) {
            // console.log(index);
            if (index == 7) {
                return true;
            }
            else{
                return false;
            }
        },

        uploadPictureAsset(file){
            const formData =  new FormData();

            formData.append('files', file);

            axios.post(process.env.VUE_APP_API_URL + 'employees/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                this.objData.picturePath = response.data.filepath;
                this.displayEditComputerGeneralDetails.editPicturePath = response.data.filepath;
                this.displayEditMonitorGeneralDetails.editPicturePath = response.data.filepath;
                this.displayEditAccessoriesGeneralDetails.editPicturePath = response.data.filepath;
                
            })
            .catch(error => {
                console.log(error);
            });
        },


        // Open Dialog
        openDialog(type){
            if (type == "laptop") {
                this.dialogAddComputer = true;
                this.objData.type = "Laptop";
                this.objData.iconType = "mdi-laptop";
            }
            else if (type == "desktop") {
                this.dialogAddComputer = true;
                this.objData.type = "Desktop";
                this.objData.iconType = "mdi-desktop-tower";
            }
            else if (type == "server") {
                this.dialogAddComputer = true;
                this.objData.type = "Server";
                this.objData.iconType = "mdi-server";
            }
            else if (type == "monitor") {
                this.dialogAddMonitor = true;
                this.objData.type = "Monitor";
                this.objData.iconType = "mdi-monitor";
            }
            else if (type == "accessories") {
                this.dialogAddAccessories = true;
                this.objData.type = "Accessories";
                this.objData.iconType = "mdi-mouse-variant";
            }
        },

        openFileDoc(item){
            this.displayImage = item.displayImage
            if(item.type=="Laptop" ||item.type=="Desktop" ||item.type=="Server") {

                for (let i = 0; i < this.displayGeneralDetails.length; i++) {
                    this.displayGeneralDetails[i].value = "";
                }
                
                this.displayGeneralDetails[0].value = item.type;
                this.displayGeneralDetails[1].value = item.empName;
                this.displayGeneralDetails[2].value = item.brandAsset;
                this.displayGeneralDetails[3].value = item.assetTag;
                this.displayGeneralDetails[4].value = item.serialNo;
                this.displayGeneralDetails[5].value = item.modelAsset;
                // this.displayGeneralDetails[6].value = item.monitorSize;
                this.displayGeneralDetails[6].value = item.warrStartDate;
                this.displayGeneralDetails[7].value = item.warrEndDate;
                this.displayGeneralDetails[8].value = item.purchaserName;
                this.displayGeneralDetails[9].value = item.purchaseDate;
                this.displayGeneralDetails[10].value = item.disposed_name;
                this.displayGeneralDetails[11].value = item.registrarName;
                this.displayGeneralDetails[12].value = item.remarksAsset;
                this.displayGeneralDetails[13].value = item.statusAsset;

                for (let i = 0; i < this.displaySpecDetails.length; i++) {
                    this.displaySpecDetails[i].value = "";
                }
                
                this.displaySpecDetails[0].value = item.processorAsset;
                this.displaySpecDetails[1].value = item.macLan;
                this.displaySpecDetails[2].value = item.macWifi;
                this.displaySpecDetails[3].value = item.ramAsset;
                this.displaySpecDetails[4].value = item.storageAsset;

                this.dialogComputerDisplayDetails = true
            }

            else if(item.type=="Monitor") {
                for (let i = 0; i < this.displayMonitorGeneralDetails.length; i++) {
                    this.displayMonitorGeneralDetails[i].value = "";
                }
                
                this.displayMonitorGeneralDetails[0].value = item.type;
                this.displayMonitorGeneralDetails[1].value = item.empName;
                this.displayMonitorGeneralDetails[2].value = item.brandAsset;
                this.displayMonitorGeneralDetails[3].value = item.assetTag;
                this.displayMonitorGeneralDetails[4].value = item.serialNo;
                this.displayMonitorGeneralDetails[5].value = item.modelAsset;
                this.displayMonitorGeneralDetails[6].value = item.monitorSize;
                this.displayMonitorGeneralDetails[7].value = item.warrStartDate;
                this.displayMonitorGeneralDetails[8].value = item.warrEndDate;
                this.displayMonitorGeneralDetails[9].value = item.purchaserName;
                this.displayMonitorGeneralDetails[10].value = item.purchaseDate;
                this.displayMonitorGeneralDetails[11].value = item.disposed_name;
                this.displayMonitorGeneralDetails[12].value = item.registrarName;
                this.displayMonitorGeneralDetails[13].value = item.remarksAsset;
                this.displayMonitorGeneralDetails[14].value = item.statusAsset;

                this.dialogMonitorDisplayDetails = true
            }

            else if(item.type=="Accessories") {
                for (let i = 0; i < this.displayAccessoriesGeneralDetails.length; i++) {
                    this.displayAccessoriesGeneralDetails[i].value = "";
                }
                
                this.displayAccessoriesGeneralDetails[0].value = item.type;
                this.displayAccessoriesGeneralDetails[1].value = item.empName;
                this.displayAccessoriesGeneralDetails[2].value = item.brandAsset;
                this.displayAccessoriesGeneralDetails[3].value = item.itemAccessories;
                this.displayAccessoriesGeneralDetails[4].value = item.assetTag;
                this.displayAccessoriesGeneralDetails[5].value = item.serialNo;
                this.displayAccessoriesGeneralDetails[6].value = item.modelAsset;
                // this.displayAccessoriesGeneralDetails[6].value = item.monitorSize;
                this.displayAccessoriesGeneralDetails[7].value = item.warrStartDate;
                this.displayAccessoriesGeneralDetails[8].value = item.warrEndDate;
                this.displayAccessoriesGeneralDetails[9].value = item.purchaserName;
                this.displayAccessoriesGeneralDetails[10].value = item.purchaseDate;
                this.displayAccessoriesGeneralDetails[11].value = item.disposed_name;
                this.displayAccessoriesGeneralDetails[12].value = item.registrarName;
                this.displayAccessoriesGeneralDetails[13].value = item.remarksAsset;
                this.displayAccessoriesGeneralDetails[14].value = item.statusAsset;

                this.dialogAccessoriesDisplayDetails = true
            }

        }

    },
    mounted(){
        this.loadDataTable();
    },
    computed: {
        filteredDataAsset(){
            if (this.searchObj) {
                return this.allData.filter(item => {
                    return Object.entries(this.searchObj).every(([key, value]) => {
                        return (item[key] ||'').toLowerCase().includes(value.toLowerCase())
                    })
                })
            }
            else{
                return this.allData
            } 
        },
    }
    
}

</script>

<style lang="scss">
@import '~scss/main';

.click-on{
    cursor: pointer;
}

.click-on:hover{
    background: lightblue;
}

#dtInventory > div:nth-child(1) > table > tbody > tr > td:nth-child(8){
    white-space: nowrap !important;
}

</style>
