<template>
  <v-container>
    <!-- Datatable -->
    <!-- Data Table -->
    <v-card class="mt-5 darkTable">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchDtAsset"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          outlined
          dense
          class="shrink"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="this.CheckLoadingTable == true"
        item-key="name"
        class="elevation-1"
        loading
        loading-text="Data is Loading... Please wait"
      ></v-data-table>
      <v-data-table
        v-if="this.CheckLoadingTable == false"
        id="dtCarBook"
        :items-per-page="5"
        :headers="headerAsset"
        :items="filteredDataAsset"
        :search="searchDtAsset"
        :custom-sort="customSort"
      >
        <!-- v-if="this.CheckLoadingTable == false"  -->
        <!-- :headers="headerAsset"
            :items="filteredDataAsset"
            :search="searchDtAsset" -->

        <template
          v-for="(header, i) in headerAsset"
          v-slot:[`header.${header.value}`]="{}"
        >
          <span @click.stop :key="i">
            <v-text-field
              :key="i"
              v-model="searchObj[header.value]"
              type="text"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
              style="font-weight: 100"
              :disabled="isDisable(i)"
            ></v-text-field>
            {{ header.text }}
          </span>
        </template>

        <!-- <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                    :color="controlBgColor(item.status)"
                    :class="controlColorFont(item.status)"
                    >
                    {{item.status}}
                    </v-chip>
                </template> -->

        <template v-slot:[`item.detailsAll`]="{ item }">
          <template>
            <v-icon dense class="primary--text" text @click="openFileDoc(item)"
              >mdi-file-document</v-icon
            >
          </template>
        </template>
      </v-data-table>
    </v-card>

    <template>
      <v-dialog
        v-model="dialogDisplayDetails"
        persistent
        scrollable
        max-height="10px"
        max-width="650px"
      >
        <v-card>
          <v-card-title class="title-bar-modul">
            <span class="text-h5">Record List</span><v-icon></v-icon>
          </v-card-title>

          <template>
            <v-tabs
              background-color="#06466E"
              centered
              dark
              v-model="tabDetail"
            >
              <v-tab href="#tab-1"> Employee </v-tab>
              <v-tab href="#tab-2"> Computer </v-tab>
              <v-tab href="#tab-3"> Monitor </v-tab>
              <v-tab href="#tab-4"> Accessories </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabDetail">
              <v-tab-item :value="'tab-1'">
                <v-card flat>
                  <v-card-text>
                    <v-simple-table class="table-details-info">
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="item in displayEmployeeDetails"
                            :key="item.name"
                          >
                            <td class="font-weight-bold">{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-2'" style="height:50vh; overflow: auto">
                <div v-if="!appendComputerInfo.length" class="h6 text-center" style="margin:20px">No Record</div>
                <v-card v-for="(items, index) in appendComputerInfo"
                            :key="index">
                             <div class="h6 text-center primary text-no-wrap rounded-pill text-white"  style="margin:20px">Record {{index +1}}</div>
                  <v-card-text >
                      <a :href="items.displayImage" target="_blank">
                        <img :src= items.displayImage style="display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 50%;
                            ">
                    </a>                    
                    <v-simple-table class="table-details-info" >
                      <template v-slot:default>
                        <tbody >
                          <v-col >
                            <div
                              style="white-space: nowrap"
                              class="text-subtitle-1 text-bold grey--text"
                            >
                              General Information
                            </div>
                          </v-col>
                          <tr v-for="item in items.generalInfo"
                            :key="item.name">
                            <td class="font-weight-bold">{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                          <v-col >
                            <div
                              style="white-space: nowrap"
                              class="text-subtitle-1 text-bold grey--text"
                            >
                              Specification
                            </div>
                          </v-col>
                          <tr v-for="item in items.specInfo"
                            :key="item.name">
                            <td class="font-weight-bold">{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-3'" style="height:50vh; overflow: auto">
                <div v-if="!appendMonitorInfo.length" class="h6 text-center" style="margin:20px">No Record</div>
                <v-card v-for="(items, index) in appendMonitorInfo"
                            :key="index">
                            <div class="h6 text-center primary text-no-wrap rounded-pill text-white"  style="margin:20px">Record {{index +1}}</div>
                  <v-card-text >
                     <a :href="items.displayImage" target="_blank">
                        <img :src= items.displayImage style="display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 50%;
                            ">
                    </a>  
                    <v-simple-table class="table-details-info" >
                      <template v-slot:default>
                          
                          
                        <tbody >
                          <tr v-for="item in items.generalInfo"
                            :key="item.name">
                            <td class="font-weight-bold">{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-4'" style="height:50vh; overflow: auto">
                <div v-if="!appendAccessoriesInfo.length" class="h6 text-center" style="margin:20px">No Record</div>
                <v-card v-for="(items, index) in appendAccessoriesInfo"
                            :key="index">
                             <div class="h6 text-center primary text-no-wrap rounded-pill text-white"  style="margin:20px">Record {{index +1}}</div>
                  <v-card-text >
                     <a :href="items.displayImage" target="_blank">
                        <img :src= items.displayImage style="display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 50%;
                            ">
                    </a>  
                    <v-simple-table class="table-details-info" >
                      <template v-slot:default>
                        <tbody >
                          <tr v-for="item in items.generalInfo"
                            :key="item.name">
                            <td class="font-weight-bold">{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </template>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-bold"
              color="primary"
              text
              @click="dialogDisplayDetails = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    CheckLoadingTable: true,
    searchDtAsset: "",
    assetFiltered: [],
    appendComputerInfo:[],
    appendMonitorInfo:[],
    appendAccessoriesInfo:[],
    searchObj: {},
    dialogDisplayDetails: false,
    headerAsset: [
      { text: "Name", value: "empname" },
      { text: "Company", value: "name" },
      { text: "No. Of Holding Asset", value: "holdingassets" },
      { text: "Detail", value: "detailsAll" },
    ],
    bodyAsset: [],
    tabDetail: [],
    empDetails: [],
    assetInfo: [],
    displayEmployeeDetails: [
      { key: "Name", value: "" },
      { key: "Phone", value: "" },
      { key: "Company", value: "" },
      { key: "Status", value: "" },
      { key: "Grade", value: "" },
      { key: "Experience", value: "" },
    ],
    displayComputerDetails: {
      generalInfo: [ 
        { key: "Type", value: "" },
        { key: "Brand", value: "" },
        { key: "Asset Tag", value: "" },
        { key: "Serial Number", value: "" },
        { key: "Model", value: "" },
        { key: "Warranty Start Date", value: "" },
        { key: "Warranty End Date", value: "" },
        { key: "Purchased By", value: "" },
        { key: "Purchased Date", value: "" },
        { key: "Disposed By", value: "" },
        { key: "Registered By", value: "" },
        { key: "Remarks", value: "" },
        { key: "Status", value: "" },
      ],
      specInfo: [
        {key: "Processor", value: ""},    
        {key: "Mac LAN", value: ""},    
        {key: "Mac WiFi", value: ""},    
        {key: "RAM", value: ""},    
        {key: "Storage", value: ""}, 
      ]      
    },
    displayMonitorDetails: [
      { key: "Type", value: "" },
      { key: "Brand", value: "" },
      { key: "Asset Tag", value: "" },
      { key: "Serial Number", value: "" },
      { key: "Model", value: "" },
      { key: "Monitor Size", value: "" },
      { key: "Warranty Start Date", value: "" },
      { key: "Warranty End Date", value: "" },
      { key: "Purchased By", value: "" },
      { key: "Purchased Date", value: "" },
      { key: "Disposed By", value: "" },
      { key: "Registered By", value: "" },
      { key: "Remarks", value: "" },
      { key: "Status", value: "" },
    ],
    displayAccessoriesDetails: [
      { key: "Type", value: "" },
      { key: "Brand", value: "" },
      { key: "Item", value: "" },
      { key: "Asset Tag", value: "" },
      { key: "Serial Number", value: "" },
      { key: "Model", value: "" },
      { key: "Warranty Start Date", value: "" },
      { key: "Warranty End Date", value: "" },
      { key: "Purchased By", value: "" },
      { key: "Purchased Date", value: "" },
      { key: "Disposed By", value: "" },
      { key: "Registered By", value: "" },
      { key: "Remarks", value: "" },
      { key: "Status", value: "" },
    ],
  }),
  methods: {
    loadDataTable() {
      this.CheckLoadingTable = true;
      this.allData = [];

      axios
        .get(process.env.VUE_APP_API_URL + "asset/countasset", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            (data[i].empname == null || data[i].empname == "") ? data[i].empname = "N/A" : data[i].empname;
            (data[i].name == null || data[i].name == "") ? data[i].name = "N/A" : data[i].name;
            data[i].holdingassets = data[i].holdingassets.toString();
            this.bodyAsset.push(data[i]);
          }

          this.allData = this.bodyAsset;
          this.headers = this.headerAsset;

          this.CheckLoadingTable = false;
          this.getAllEmployee();
          this.getAllInfo();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllEmployee() {
      axios
        .get(process.env.VUE_APP_API_URL + "employees/all", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          let data = response.data;
          this.empDetails = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllInfo() {
      axios
        .get(process.env.VUE_APP_API_URL + "asset/all", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.token,
          },
        })
        .then((response) => {
          let data = response.data;
          this.assetInfo = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    isDisable(index) {
      if (index == 3) {
        return true;
      } else {
        return false;
      }
    },

    customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);
            
            items.sort((a, b) => {
                if (index[0]=='dtApplied' || index[0]=='startDateTime' || index[0]=='endDateTime') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                    if (!isDesc[0]) {
                        return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                    }
                    else {
                        return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                    }
                    }
                }
            });
            return items;
        },


    openFileDoc(item) {  

      if(this.assetFiltered.length > 0 || this.appendComputerInfo.length > 0 || this.appendMonitorInfo.length > 0 || this.appendAccessoriesInfo.length > 0) {
          this.assetFiltered=[];
          this.appendComputerInfo=[];
          this.appendMonitorInfo=[];
          this.appendAccessoriesInfo=[];
      } else {
          this.assetFiltered=[];
          this.appendComputerInfo=[];
          this.appendMonitorInfo=[];
          this.appendAccessoriesInfo=[];
      }      

      for (let j = 0; j < this.empDetails.length; j++) {
        if (item.idemp == this.empDetails[j].idemp) {
          this.displayEmployeeDetails[0].value = this.empDetails[j].name;
          this.displayEmployeeDetails[1].value = this.empDetails[j].phone;
          this.displayEmployeeDetails[2].value = this.empDetails[j].company;
          this.displayEmployeeDetails[3].value = this.empDetails[j].status;
          this.displayEmployeeDetails[4].value = this.empDetails[j].grade;
          this.displayEmployeeDetails[5].value = this.empDetails[j].experience;
        }
      }

      this.assetFiltered = this.assetInfo.filter((x) => x.idemp == item.idemp);

      for (let j = 0; j < this.assetFiltered.length; j++) {

        if(this.assetFiltered[j].assetname == "Laptop" || this.assetFiltered[j].assetname == "Desktop" || this.assetFiltered[j].assetname == "Server") {
              var displayComputerDetails = {
                generalInfo: [ 
                  { key: "Type", value: this.assetFiltered[j].assetname },
                  { key: "Brand", value: this.assetFiltered[j].brand },
                  { key: "Asset Tag", value: this.assetFiltered[j].asset_tag },
                  { key: "Serial Number", value: this.assetFiltered[j].serial_no },
                  { key: "Model", value: this.assetFiltered[j].model },
                  { key: "Warranty Start Date", value: this.convertDateString(this.assetFiltered[j].start_date_warranty) },
                  { key: "Warranty End Date", value: this.convertDateString(this.assetFiltered[j].end_date_warranty) },
                  { key: "Purchased By", value: this.assetFiltered[j].purchaser_name },
                  { key: "Purchased Date", value: this.convertDateString(this.assetFiltered[j].date_purchased) },
                  { key: "Disposed By", value: this.assetFiltered[j].disposed_name},
                  { key: "Registered By", value: this.assetFiltered[j].registrar_name },
                  { key: "Remarks", value: this.assetFiltered[j].remarks},
                  { key: "Status", value: this.assetFiltered[j].status },
                ],
                specInfo: [
                  {key: "Processor", value: this.assetFiltered[j].processor},    
                  {key: "Mac LAN", value: this.assetFiltered[j].mac_address_lan},    
                  {key: "Mac WiFi", value: this.assetFiltered[j].mac_address_wifi},    
                  {key: "RAM", value: this.assetFiltered[j].ram},    
                  {key: "Storage", value: this.assetFiltered[j].storage}, 
                ],
                displayImage: this.assetFiltered[j].picture
              }
              this.appendComputerInfo.push(displayComputerDetails)
        } else if(this.assetFiltered[j].assetname == "Monitor") {
            var displayMonitorDetails = {
              generalInfo : [
                { key: "Type", value: this.assetFiltered[j].assetname },
                { key: "Brand", value: this.assetFiltered[j].brand },
                { key: "Asset Tag", value: this.assetFiltered[j].asset_tag },
                { key: "Serial Number", value: this.assetFiltered[j].serial_no },
                { key: "Model", value: this.assetFiltered[j].model },
                { key: "Monitor Size", value: this.assetFiltered[j].monitor_size },
                { key: "Warranty Start Date", value: this.convertDateString(this.assetFiltered[j].start_date_warranty) },
                { key: "Warranty End Date", value: this.convertDateString(this.assetFiltered[j].end_date_warranty) },
                { key: "Purchased By", value: this.assetFiltered[j].purchaser_name },
                { key: "Purchased Date", value: this.convertDateString(this.assetFiltered[j].date_purchased) },
                { key: "Disposed By", value: this.assetFiltered[j].disposed_name },
                { key: "Registered By", value: this.assetFiltered[j].registrar_name },
                { key: "Remarks", value: this.assetFiltered[j].remarks },
                { key: "Status", value: this.assetFiltered[j].status },
              ],
              displayImage: this.assetFiltered[j].picture
            }
            this.appendMonitorInfo.push(displayMonitorDetails)

        } else if(this.assetFiltered[j].assetname == "Accessories" ) {
            var displayAccessoriesDetails = {
              generalInfo : [
                { key: "Type", value: this.assetFiltered[j].assetname },
                { key: "Brand", value: this.assetFiltered[j].brand },
                { key: "Item", value: this.assetFiltered[j].item },
                { key: "Asset Tag", value: this.assetFiltered[j].asset_tag },
                { key: "Serial Number", value: this.assetFiltered[j].serial_no },
                { key: "Model", value: this.assetFiltered[j].model },
                { key: "Warranty Start Date", value: this.convertDateString(this.assetFiltered[j].start_date_warranty) },
                { key: "Warranty End Date", value: this.convertDateString(this.assetFiltered[j].end_date_warranty) },
                { key: "Purchased By", value: this.assetFiltered[j].purchaser_name },
                { key: "Purchased Date", value: this.convertDateString(this.assetFiltered[j].date_purchased) },
                { key: "Disposed By", value: this.assetFiltered[j].disposed_name },
                { key: "Registered By", value: this.assetFiltered[j].registrar_name },
                { key: "Remarks", value: this.assetFiltered[j].remarks },
                { key: "Status", value: this.assetFiltered[j].status },
              ],
              displayImage: this.assetFiltered[j].picture
            }
            this.appendAccessoriesInfo.push(displayAccessoriesDetails)
        }        
      }
      this.dialogDisplayDetails = true;
    },
  },
  mounted() {
    this.loadDataTable();
  },
  computed: {
    filteredDataAsset() {
      if (this.searchObj) {
        return this.allData.filter((item) => {
          return Object.entries(this.searchObj).every(([key, value]) => {
            return (item[key] || "")
              .toLowerCase()
              .includes(value.toLowerCase());
          });
        });
      } else {
        return this.allData;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~scss/main";

.click-on {
  cursor: pointer;
}

.click-on:hover {
  background: lightblue;
}
</style>